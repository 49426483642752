import { useEffect, useState } from 'react';
import useAxios from '../services/useAxios';

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(false);
    const { axios } = useAxios();

    const getProfile = async () => {
        if (loading || profile) {
            return;
        }
        setLoading(true);

        try {
            let response = await axios.get('/api/profile');

            if (response.data) {
                setProfile(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProfile();
    });

    return (
        <main>
            <h2>Dashboard</h2>
            <p>Just a basic welcome page</p>
        </main>
    );
}
