import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';
import useToken from '../services/useToken';

export default function Login({ logo }) {
    const { setToken } = useToken();
    const [loginToken, setLoginToken] = useState();
    const [alertInformation, setAlertInformation] = useState();
    const navigate = useNavigate();

    const showAlert = (message, type) => {
        setAlertInformation({
            message: message,
            type: type,
        });

        setTimeout(() => {
            setAlertInformation(null);
        }, 1500);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios
            .post('/api/login/token', { token: loginToken })
            .then((response) => {
                try {
                    setToken(response.data.jwt);
                    setTimeout(() => {
                        navigate('/');
                        window.location.reload(false);
                    }, 500);
                } catch (e) {
                    console.log(e);
                    showAlert('Error during login', 'danger');
                }
            })
            .catch((e) => {
                console.log(e.response);
                showAlert(e.response.statusText + ' ' + e.response.data.message, 'danger');
            });
    };

    const ssoLogin = (event) => {
        event.preventDefault();
        window.location.href = '/login/sso';
    };

    const backgroundImage = {
        backgroundImage: 'url(' + logo + ')',
        height: '207px',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className="container-fluid">
            <section className="text-center">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <Alert alertInformation={alertInformation} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-4 mt-5">
                        <div className="p-5 bg-image" style={backgroundImage}></div>

                        <div
                            className="card mx-4 mx-md-5 shadow-5-strong"
                            style={{
                                marginTop: '-100px',
                                background: 'hsla(0, 0%, 100%, 0)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <div className="card-body py-5 px-md-5">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-8">
                                        <h2 className="fw-bold mb-5">Api Documentation</h2>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-outline mb-4">
                                                <input
                                                    type="password"
                                                    id="tokenEntry"
                                                    placeholder="Token"
                                                    className="form-control"
                                                    onChange={(e) => setLoginToken(e.target.value)}
                                                />
                                            </div>

                                            <button
                                                disabled={!loginToken ? 'disabled' : ''}
                                                type="submit"
                                                className="btn btn-primary btn-block mb-4"
                                            >
                                                Login with Token
                                            </button>
                                            <h4 className="fw-bold mb-4">- or -</h4>
                                            <button type="button" className="btn btn-primary btn-block mb-4" onClick={ssoLogin}>
                                                Login with SSO
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
