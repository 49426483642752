import useRedirect from '../../services/useRedirect';

export default function Forbidden() {
    const { toHome } = useRedirect();

    return (
        <main>
            <h2>Forbidden</h2>
            <p>Oops... You are not authorized to access requested page</p>
            <p>
                <button className="btn btn-primary btn-lg" onClick={toHome}>
                    go to Dashboard
                </button>
            </p>
        </main>
    );
}
