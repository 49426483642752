import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import useAxios from '../services/useAxios';

export default function Doc() {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(<div>Loading</div>);
    const location = useLocation();
    const { axios } = useAxios();

    const getResources = async (path) => {
        if (loading) {
            return;
        }
        setLoading(true);

        try {
            let response = await axios.get(path);

            if (response.data) {
                setContent(<SwaggerUI spec={response.data} docExpansion="list" />);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);

            setContent(<Navigate to="/400" replace state={{ path: location.pathname }} />);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getResources(`/api/documentations/${params.name}`);
    }, [params.name]);

    return <div id="api-data">{content}</div>;
}
