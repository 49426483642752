import useRedirect from '../../services/useRedirect';

export default function Unauthorized() {
    const { toLogin } = useRedirect();

    return (
        <main>
            <h2>Oops... Unauthorized</h2>
            <p>You must be logged in to access the requested page</p>
            <p>
                <button className="btn btn-primary btn-lg " onClick={toLogin}>
                    Login
                </button>
            </p>
        </main>
    );
}
