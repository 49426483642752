import useRedirect from '../../services/useRedirect';

export default function NotFound() {
    const { toHome } = useRedirect();

    return (
        <main>
            <h2>Not Found</h2>
            <p>Oops... Resource was not found</p>
            <p>
                <button className="btn btn-primary btn-lg" onClick={toHome}>
                    go to Dashboard
                </button>
            </p>
        </main>
    );
}
