import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import logo from './avafinlogo.svg';
import NavigationBar from './components/NavigationBar';
import './css/app.css';
import './css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
import Doc from './pages/Doc';
import Login from './pages/Login';
import Ssologin from './pages/Ssologin';
import TokenAdministration from './pages/TokenAdministration';
import { errorRoutes, unauthorizedRoute } from './routes/errors';
import useToken from './services/useToken';

function BrowserContainer({ permissions, removeTokenCallback, username }) {
    const location = useLocation();
    const hideNavigationBarRoutes = [unauthorizedRoute.path];

    return (
        <>
            {hideNavigationBarRoutes.includes(location.pathname) ? null : (
                <NavigationBar logo={logo} permissions={permissions} handleLogout={removeTokenCallback} />
            )}
            <Routes>
                <Route path="/documentations/:name/" element={<Doc />} />
                <Route path="/administration/:name/" element={<TokenAdministration username={username} />} />
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/ssologin" element={<Dashboard />} />
                {errorRoutes.map(({ path, main }) => (
                    <Route key={path} path={path} element={main()} />
                ))}
            </Routes>
        </>
    );
}

function App() {
    let permissions = null;
    let username = '';

    const { token, removeToken } = useToken();

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    if (token) {
        permissions = parseJwt(token);
        if (permissions.username) {
            username = permissions.username;
        }
    }

    return (
        <div className="app">
            <BrowserRouter>
                {permissions ? (
                    <BrowserContainer permissions={permissions.permissions} removeTokenCallback={removeToken} username={username} />
                ) : (
                    <Routes>
                        <Route key="sso" path="/ssologin" element={<Ssologin />} />
                        <Route path="/" element={<Login logo={logo} />} />
                    </Routes>
                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
