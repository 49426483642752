import React from 'react';
import useRedirect from '../services/useRedirect';
import useToken from '../services/useToken';

export default function Ssologin() {
    const { token, setToken } = useToken();
    const { toPath } = useRedirect();

    if (token) {
        setToken(token);
        toPath('/');
    } else {
        toPath('/401');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        toPath('/');
    };

    return (
        <main>
            <h2>Oops... Authentication failed</h2>
            <p>
                <button className="btn btn-primary btn-lg " onClick={handleSubmit}>
                    Click here to Login
                </button>
            </p>
        </main>
    );
}
