import React from 'react';
import '../css/bootstrap.min.css';

export default function Alert({ alertInformation }) {
    return (
        <>
            {alertInformation ? (
                <div className={'alert mt-2 alert-dismissible fade show alert-' + alertInformation.type}>{alertInformation.message}</div>
            ) : (
                <div className="alert mt-2"></div>
            )}
        </>
    );
}
