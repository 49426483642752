import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useToken from './useToken';

export default function useAxios() {
    const { token, removeToken } = useToken();
    const navigate = useNavigate();

    const http = axios.create();

    http.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        function (error) {
            console.log(error);
            // Do something with request error
            return Promise.reject(error);
        }
    );

    http.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 401) {
                removeToken();
                navigate('/401');
            }

            if (error.response.status === 403) {
                navigate('/403');
            }

            return Promise.reject(error);
        }
    );

    return {
        axios: http,
    };
}
