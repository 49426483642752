import { useLocation } from 'react-router-dom';
import useRedirect from '../../services/useRedirect';

export default function Error() {
    const { state } = useLocation();
    const { toPath, toHome } = useRedirect();

    return (
        <main>
            <h2>Oops... Error</h2>
            <p>Something happened while you were trying to reach {state.path}</p>
            <p>
                You can{' '}
                <button className="btn btn-primary btn-lg" onClick={toPath(state.path)}>
                    try again
                </button>{' '}
                or
                <button className="btn btn-primary btn-lg" onClick={toHome}>
                    go to Dashboard
                </button>
            </p>
        </main>
    );
}
