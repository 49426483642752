import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../css/bootstrap.min.css';

function NavigationLinks({ routes, path }) {
    return (
        <>
            {routes.map((route, index) => (
                <div key={index} className="api-link">
                    <NavLink to={path + '/' + route} style={{ textTransform: 'capitalize' }}>
                        {route}
                    </NavLink>
                </div>
            ))}
        </>
    );
}

function NavigationList({ items }) {
    return (
        <>
            {items.map((item, index) => (
                <div key={index}>
                    <h3 style={{ marginTop: '30px', textTransform: 'capitalize' }}>{item.name}</h3>
                    <NavigationLinks routes={item.resources} path={item.name} />
                </div>
            ))}
        </>
    );
}

function NavigationBar({ permissions, logo, handleLogout }) {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogout();

        navigate('/');
        window.location.reload(false);
    };

    return (
        <div className="side-bar">
            <div className="side-bar-header">
                <img className="logo-img" src={logo} alt="logo" />
            </div>
            <div className="side-bar-body">
                <NavigationList items={permissions.sections} />
                {permissions ? (
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <button className="btn btn-lg btn-primary mt-4" type="submit">
                                Logout
                            </button>
                        </form>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}

export default NavigationBar;
