import React, { useEffect, useState } from 'react';
import { ArrowRepeat, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import Alert from '../components/Alert';
import useAxios from '../services/useAxios';

const emptyListInformation = [
    {
        name: '-- no --',
        token: '-- token --',
        user: '-- found --',
    },
];

const tokenHandler = {
    getTokens: (callback, axios) => {
        axios.get('/api/administration/tokens').then((response) => {
            callback(response.data);
        });
    },

    saveTokens: (data, callback, alert, axios) => {
        axios
            .post('/api/administration/tokens', data)
            .then((response) => {
                alert('Token saved successful and copied to clipboard', 'success');
                callback(response.data);
            })
            .catch((e) => {
                alert(e.response.data.message, 'danger');
            });
    },

    deleteToken: (name, callback, alert, axios) => {
        axios
            .delete('/api/administration/tokens/' + encodeURIComponent(name))
            .then(() => {
                callback(name);
                alert('Token deleted sucessful', 'success');
            })
            .catch((e) => {
                alert(e.response.data.message, 'danger');
            });
    },

    exists: (name, tokens) => {
        for (let tokenId in tokens) {
            if (tokens[tokenId].name === name) {
                return true;
            }
        }

        return false;
    },

    copyTokenToClipboard: (token) => {
        if (location.protocol !== 'https:') {
            /** backup for HTTP */
            var textArea = document.createElement('textarea');
            textArea.value = token;

            // Avoid scrolling to bottom
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        } else {
            /** only available on HTTPS */
            navigator.clipboard.writeText(token);
        }
    },
};

const passwordHandler = {
    generate: () => {
        let chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let passwordLength = 20;
        let password = '';

        for (let i = 0; i <= passwordLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }

        return password;
    },
};

function TokenInput({ token, setToken, copied, setCopied }) {
    const copyTokenToClipboard = () => {
        tokenHandler.copyTokenToClipboard(token);

        setCopied(true);
    };

    const generateToken = () => {
        setToken(passwordHandler.generate());
        setCopied(false);
    };

    return (
        <div className="form-group mr-2">
            <div className="input-group">
                <div className="input-group-prepend">
                    <button type="button" className="input-group-text btn-secondary shadow-none" onClick={generateToken}>
                        <ArrowRepeat />
                    </button>
                </div>
                <input
                    type="text"
                    className="form-control"
                    id="tokenInput"
                    readOnly
                    value={token}
                    onChange={() => {
                        setCopied(false);
                    }}
                />
                <div className="input-group-append">
                    <button type="button" className="input-group-text btn-secondary shadow-none" onClick={copyTokenToClipboard}>
                        {copied ? <ClipboardCheck /> : <Clipboard />}
                    </button>
                </div>
            </div>
        </div>
    );
}

function NewToken({ handleSaveFunction }) {
    const [name, setName] = useState('');
    const [token, setToken] = useState(passwordHandler.generate());
    const [copied, setCopied] = useState(false);

    const newEntry = (event) => {
        event.preventDefault();

        handleSaveFunction(name, token);
        setName('');
        setToken(passwordHandler.generate());
        setCopied(false);
    };

    return (
        <form className="form-inline mt-3" onSubmit={newEntry}>
            <div className="form-group mr-2">
                <label htmlFor="fieldName" className="sr-only">
                    Name
                </label>
                <input
                    id="fieldName"
                    className="form-control"
                    name="name"
                    type="text"
                    autoComplete="off"
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    value={name}
                />
            </div>
            <TokenInput token={token} setToken={setToken} copied={copied} setCopied={setCopied} />
            <button disabled={!name ? 'disabled' : ''} className="btn btn-primary" value="create">
                Create Token
            </button>
        </form>
    );
}

function TokenEntry({ data, onClickCopyToken, onClickDeleteEntry }) {
    const [copied, setCopied] = useState(false);

    const copyToken = () => {
        onClickCopyToken(data.token);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    const deleteEntry = () => {
        onClickDeleteEntry(data.name);
    };

    const isEntryFromEmptyList = () => {
        return data.name === emptyListInformation[0].name;
    };

    let copyButton;

    if (!isEntryFromEmptyList()) {
        copyButton = (
            <button type="button" className="border-0 bg-transparent" onClick={copyToken}>
                {copied ? <ClipboardCheck /> : <Clipboard />}
            </button>
        );
    }

    return (
        <>
            <td>{data.name}</td>
            <td>
                <div className="d-flex flex justify-content-between">
                    <span>{data.token}</span>
                    {copyButton}
                </div>
            </td>
            <td>{data.user}</td>
            {!isEntryFromEmptyList() ? (
                <td>
                    <button className="btn btn-danger btn-sm" onClick={deleteEntry}>
                        Delete
                    </button>
                </td>
            ) : (
                <td />
            )}
        </>
    );
}

function TokenList({ data, handleCopyFunction, handleDeleteFunction }) {
    const copyToken = (token) => {
        handleCopyFunction(token);
    };

    const deleteEntry = (name) => {
        handleDeleteFunction(name);
    };

    if (!data || (data && data.length <= 0)) {
        data = emptyListInformation;
    }

    return (
        <table className="table table-striped mt-3 table-sm">
            <thead className="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Token</th>
                    <th>Created By</th>
                    <th style={{ width: 'auto' }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {data.map((entry, key) => {
                    return (
                        <tr key={key}>
                            <TokenEntry data={entry} onClickCopyToken={copyToken} onClickDeleteEntry={deleteEntry} />
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default function TokenAdministration({ username }) {
    const [tokenData, setTokenData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [alertInformation, setAlertInformation] = useState();

    const { axios } = useAxios();

    const showAlert = (message, type) => {
        setAlertInformation({
            message: message,
            type: type,
        });

        setTimeout(() => {
            setAlertInformation(null);
        }, 1500);
    };

    const handleCopy = (token) => {
        tokenHandler.copyTokenToClipboard(token);
    };

    const handleDelete = (name) => {
        tokenHandler.deleteToken(name, handleRemoveTokenData, showAlert, axios);
    };

    const handleSave = (name, token) => {
        console.log(token);
        if (!name || (name && name.length <= 0)) {
            showAlert('Enter token name!', 'danger');
            return;
        }

        if (!token || (token && token.length < 10)) {
            showAlert('Token is too small!', 'danger');
            return;
        }

        if (tokenHandler.exists(name, tokenData)) {
            showAlert('A token with that name already exists!', 'danger');
            return;
        }

        let data = {
            name: name,
            token: token,
            user: username ? username : 'tokenUser',
        };

        tokenHandler.saveTokens(data, handleAddTokenData, showAlert, axios);
    };

    const handleAddTokenData = (newTokenEntry) => {
        let newTokenData = [];
        if (tokenData.length <= 1 && tokenHandler.exists(emptyListInformation[0].name, tokenData)) {
            newTokenData.push(newTokenEntry);
        } else {
            newTokenData = [].concat(tokenData, newTokenEntry);
        }

        setTokenData(newTokenData);
    };

    const handleRemoveTokenData = (tokenToRemove) => {
        let newTokenData = [];
        for (const token of tokenData) {
            if (token.name !== tokenToRemove) {
                newTokenData.push(token);
            }
        }

        if (newTokenData.length <= 0) {
            newTokenData = emptyListInformation;
        }

        setTokenData(newTokenData);
    };

    useEffect(() => {
        if (!isLoaded) {
            tokenHandler.getTokens(setTokenData, axios);
            setIsLoaded(true);
        }
    });

    return (
        <>
            <div className="container">
                <Alert alertInformation={alertInformation} />
                <div className="row justify-content-center mt-5">
                    <h3>Token Administration</h3>
                </div>
                <NewToken handleSaveFunction={handleSave} />
                <div className="input-group">
                    <TokenList data={tokenData} handleCopyFunction={handleCopy} handleDeleteFunction={handleDelete} />
                </div>
            </div>
        </>
    );
}
