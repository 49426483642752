import Error from '../pages/errors/Error';
import Forbidden from '../pages/errors/Forbidden';
import NotFound from '../pages/errors/NotFound';
import Unathorized from '../pages/errors/Unauthorized';

const unauthorizedRoute = {
    path: '/401',
    name: 'Unauthorized',
    main: () => <Unathorized />,
    description: 'Unathorized',
};

const errorRoutes = [
    {
        path: '*',
        name: 'Not Found',
        main: () => <NotFound />,
        description: 'Error 404',
    },
    {
        path: '/400',
        name: 'Error',
        main: () => <Error />,
        description: 'Error 400',
    },
    unauthorizedRoute,
    {
        path: '/404',
        name: 'Not Found',
        main: () => <NotFound />,
        description: 'Error 404',
    },
    {
        path: '/403',
        name: 'Forbidden',
        main: () => <Forbidden />,
        description: 'Error 403',
    },
    {
        path: '/500',
        name: 'Error',
        main: () => <Error />,
        description: 'Error',
    },
];

export { errorRoutes, unauthorizedRoute };
