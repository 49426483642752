import { useNavigate } from 'react-router-dom';

export default function useRedirect() {
    const navigate = useNavigate();

    const toPath = (path) => {
        navigate(path);
    };

    const toHome = () => {
        navigate('/');
    };

    const toLogin = () => {
        navigate('/');
        window.location.reload(false);
    };

    return {
        toPath: toPath,
        toHome: toHome,
        toLogin: toLogin,
    };
}
