import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        let jwtToken = localStorage.getItem('jwt');

        if (!jwtToken) {
            jwtToken = document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
        }

        return jwtToken;
    };

    const [token] = useState(getToken());

    const saveToken = (jwtToken) => {
        if (!jwtToken) {
            return;
        }

        localStorage.setItem('jwt', jwtToken);
    };

    const removeToken = () => {
        localStorage.removeItem('jwt');
        localStorage.clear();
        document.cookie = 'jwt=; Max-Age=0';
    };

    return {
        setToken: saveToken,
        removeToken: removeToken,
        token,
    };
}
